<template>
  <div class="tokyo_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div class="image avatar_img" :style="{ backgroundImage: 'url(' + src1 + ')' }"></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <h1 class="name"> {{ $t("HOME.name") }}</h1>
        <p class="job">
          {{ $t("HOME.description") }}
        </p>
        <p class="job">{{ $t("HOME.second-description") }}</p>
        <Social />
        <div class=btn-contact-container>
          <button @click="changeTab" class="btn-contact">{{ $t("HOME.contact") }}</button>
        </div>
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
import Social from "./Social";
export default {
  components: {
    Social,
  },
  data() {
    return {
      src1: require("../assets/img/slider/ph-home.jpg"),
    };
  },
  methods: {
    changeTab() {
      this.$emit('setActiveTab', 6)
    },
  },
  metaInfo() {
    return {
      title: 'Pedro Henrique Cunha - Atleta de Wakeboard e Inspirador Mineiro',
      meta: [
        { property: 'og:title', content: 'Pedro Henrique Cunha - Atleta de Wakeboard e Inspirador Mineiro' },
        { property: 'og:description', content: 'Conheça a emocionante trajetória de Pedro Henrique Cunha, um apaixonado atleta de wakeboard de Uberlândia. Superação, fé e determinação em sua jornada inspiradora.' },

      ],

    };
  },
};
</script>

<style lang="scss" scoped>
.btn-contact {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  background-color: #000;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.name {
  font-size: 30px !important;
}

.btn-contact {
  font-size: 15px;
  line-height: 15px;
  padding: 14px 40px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn-contact:hover {
  letter-spacing: 2px;
}

.btn-contact-container {
  padding-top: 50px;
}
</style>
