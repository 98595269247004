<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>{{ $t("CONTACT.title") }}</span>
          <h1>{{ $t("CONTACT.description") }}</h1>
          <p>{{ $t("CONTACT.question") }}</p>
          <p>{{ $t("CONTACT.second-description") }}</p>
        </div>
      </div>
    </div>
    <!-- End Title -->

    <div class="fields">
      <ValidationObserver ref="contactForm" v-slot="{ handleSubmit }">
        <form class="contact_form" @submit.prevent="handleSubmit(onSubmit)">
          <div class="first">
            <ul>
              <ValidationProvider name="name" rules="required">
                <li>
                  <input v-model="formData.name" type="text" :placeholder="$t('CONTACT.name')" autocomplete="off" />
                </li>
              </ValidationProvider>

              <ValidationProvider name="email" rules="required|email">
                <li>
                  <input type="text" rules="required|email" v-model="formData.email" :placeholder="$t('CONTACT.email')" />
                </li>
              </ValidationProvider>
              <ValidationProvider name="message" rules="required">
                <li>
                  <textarea v-model="formData.message" :placeholder="$t('CONTACT.message')"></textarea>
                </li>
              </ValidationProvider>
            </ul>
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" @click="send" class="ib-button">{{ $t("CONTACT.send-message") }}</button>
          </div>
          <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        </form>

      </ValidationObserver>

    </div>

    <!-- END FIELDS -->
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { firebaseDb } from "../firebaseConfig";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      errorMessage: "",
      successMessage: "",
    };
  },


  methods: {
    onSubmit() {
      console.log(this.formData);
    },
    async send() {
      try {

        // Validar os campos do formulário
        if (this.validateForm()) {
          await firebaseDb.collection("mail").add({

            to: ['phcunhawake@gmail.com'],
            message: {
              subject: 'Formulário de Contato',
              text: 'This is the plaintext section of the email body.',
              html: `<p><strong>Nome:</strong> ${this.formData.name}</p>
                    <p><strong>E-mail:</strong> ${this.formData.email}</p>
                    <p><strong>Mensagem:</strong> ${this.formData.message}</p>
                  `
            },
          });

          this.formData.name = "";
          this.formData.email = "";
          this.formData.message = "";

          this.$refs.contactForm.$children.forEach((child) => {
            if (child.resetValidation) {
              child.resetValidation();
            }
          });

          this.successMessage = "Mensagem enviada com sucesso!"; // Definindo mensagem de sucesso
          this.errorMessage = ""; // Limpar mensagem de erro
        } else {
          this.errorMessage = this.$t("CONTACT.alert");
          this.successMessage = ""; // Limpar mensagem de sucesso
        }
      } catch (error) {
        this.errorMessage =
          "Ocorreu um erro ao enviar o formulário. Por favor, tente novamente mais tarde.";
        this.successMessage = ""; // Limpar mensagem de sucesso
      }
    },
    validateForm() {
      // Validar campos do formulário
      if (
        this.formData.name &&
        this.formData.email &&
        this.formData.message
        // Adicione outras condições de validação conforme necessário
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  metaInfo() {
    return {
      title: 'Contato com Pedro Henrique Cunha - Oportunidades de Patrocínio, Eventos e Apoio',
      meta: [
        { property: 'og:title', content: 'Contato - Entre em Contato com Pedro Henrique Cunha' },
        { property: 'og:description', content: 'Entre em contato com Pedro Henrique Cunha para oportunidades de patrocínio, convites para eventos ou simplesmente para compartilhar seu apoio.' },
      ],
    };
  },


};
</script>

<style lang="scss" scoped>
.copyright-contact {
  display: flex;
}

.copyright-text {
  justify-content: end;

}

.error-message {
  color: red;
}

.success-message {
  color: green;
}
</style>