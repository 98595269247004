<template>
    <div class="tokyo_tm_skills container">

        <div class="title_flex">
            <div class="left">
                <div>
                    <div class="tokyo_tm_title">
                        <span>{{ $t("SKILLS.title") }}</span>
                    </div>
                    <h3 class="skills-title">{{ $t("SKILLS.description") }}</h3>
                    <div class="skills-container">
                        <div class="img-skills-container">
                            <img class="img-skills" src="../assets/img/about/skills.png" alt="imagem de troféu">

                        </div>
                        <p>{{ $t("SKILLS.first-description") }}
                            <span class="category-span">{{ $t("SKILLS.span-description") }}</span>
                            {{ $t("SKILLS.second-description") }}
                        </p>
                    </div>
                    <div class="skills-container">

                        <div class="img-skills-container">
                            <img class="img-skills" src="../assets/img/about/skills.png" alt="imagem de troféu">

                        </div>
                        <p> {{ $t("SKILLS.third-description") }}
                            <span class="category-span"> {{ $t("SKILLS.second-span-description") }}</span>
                            {{ $t("SKILLS.fourth-description") }}
                        </p>
                    </div>
                    <h3 class="skills-title"> {{ $t("SKILLS.description-of-other-competitions") }}</h3>
                    <div class="skills-container">
                        <div class="img-skills-container">
                            <img class="img-skills img-medal " src="../assets/img/about/medal.png" alt="imagem de medalha">
                        </div>

                        <p> {{ $t("SKILLS.fifth-description") }}
                            <span class="category-span">{{ $t("SKILLS.friday-span-description") }}</span><br>
                            {{ $t("SKILLS.seventh-description") }}
                        </p>
                    </div>
                    <div class="skills-container">
                        <div class="img-skills-container">
                            <img class="img-skills  img-medal " src="../assets/img/about/medal.png" alt="imagem de medalha">

                        </div>
                        <p>{{ $t("SKILLS.competed-in-the-pro-category") }}
                            <span class="category-span">{{ $t("SKILLS.plastic-playground-championship") }}</span>
                            {{ $t("SKILLS.date") }}
                        </p>
                    </div>
                    <div class="skills-container">
                        <div class="img-skills-container">
                            <img class="img-skills" src="../assets/img/about/skills.png" alt="imagem de troféu">

                        </div>
                        <p> {{ $t("SKILLS.wakeboard-champion") }}
                            <span class="category-span"> {{ $t("SKILLS.pro-category") }}</span>
                            {{ $t("SKILLS.second-date") }}
                        </p>
                    </div>
                    <div class="skills-container">
                        <div class="img-skills-container">
                            <img class="img-skills" src="../assets/img/about/skills.png" alt="imagem de troféu">

                        </div>
                        <p> {{ $t("SKILLS.sao-paulo-wakeboard-champion") }}
                            <span class="category-span"> {{ $t("SKILLS.second-pro-second") }}</span>
                            {{ $t("SKILLS.third-second-date") }}
                        </p>
                    </div>

                    <div class="skills-container">
                        <div class="img-skills-container">
                            <img class="img-skills" src="../assets/img/about/skills.png" alt="imagem de troféu">

                        </div>
                        <p> {{ $t("SKILLS.champion-of-the-brazilian") }}
                            <span class="category-span">{{ $t("SKILLS.third-pro-second") }}</span>
                            {{ $t("SKILLS.fourth-date") }}
                        </p>
                    </div>

                </div>
                <div class="tokyo_tm_title_skills">
                    <span> {{ $t("GOALS.title") }}</span>
                </div>
                <div class="counter">

                    <div class="check-container">
                        <div class="img-check-container">
                            <img class="img-check" src="../assets/img/about/check.png" alt="logo check">
                        </div>
                        <p> {{ $t("GOALS.first-description") }}</p>
                    </div>

                    <div class="check-container">
                        <div class="img-check-container">
                            <img class="img-check" src="../assets/img/about/check.png" alt="logo check">
                        </div>
                        <p>{{ $t("GOALS.second-description") }}</p>
                    </div>

                    <div class="check-container">
                        <div class="img-check-container">
                            <img class="img-check" src="../assets/img/about/check.png" alt="logo check">
                        </div>
                        <p>{{ $t("GOALS.third-description") }}</p>
                    </div>
                </div>

                <!-- End .tokyo_tm_modalbox_about -->
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "Skills",
 
    metaInfo() {
        return {
            title: 'Skills de Pedro Henrique Cunha - Conquistas e Metas no Wakeboard',
            meta: [
                { property: 'og:title', content: 'Conquistas em Wakeboard - Pedro Henrique Cunha' },
                { property: 'og:description', content: 'Explore as recentes realizações em wakeboard de Pedro Henrique Cunha, campeão em várias competições nacionais e internacionais.' },
                { property: 'og:image', content: 'https://www.pedrocunhawake.com.br/ph-home.jpg' },
            ],
        };
    },

}


</script>
<style lang="scss" scoped>
.tokyo_tm_about .description .right>ul>li a:hover {
    color: #000;
    letter-spacing: 1px;
    cursor: pointer;
}

.skills-title {
    font-weight: 700;
    color: #000;
    font-size: 20px;
    padding-bottom: 20px;
}

.skills-container {
    display: flex;
    padding-bottom: 20px;
}

.img-skills {
    width: 40px;
}

.category-span {
    color: rgb(56 181 215);
    font-weight: bold;
    background-color: transparent;
}

.img-check {
    width: 20px;
}

.check-container {
    display: flex;
    padding-bottom: 20px;
}

.img-check-container,
.img-skills-container {
    padding-right: 10px;
}

.tokyo_tm_title_skills span {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.04);
    text-transform: uppercase;
    padding: 4px 10px;
    font-weight: 600;
    font-size: 12px;
    color: #333;
    font-family: "Montserrat";
    letter-spacing: 0px;
    margin-bottom: 15px;
}

@media screen and (max-width: 424px) {
    .img-medal {
        width: 100% !important;

    }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
    .img-medal {
        width: 90% !important;
    }
}

@media screen and (min-width: 768px) {
    .img-medal {
        width: 40px !important;
    }
}
</style>