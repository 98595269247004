<template>
  <div class="tokyo_tm_about">
    <div class="tokyo_tm_title">
      <span>{{ $t("ABOUT.title") }}</span>
    </div>
    <div>
      <div class="about_image" style="width: 100%; max-height: 450px; overflow: hidden;">
        <img src="../assets/img/portfolio/ph5.jpg" alt="about" style="width: 100%; height: auto; object-fit: cover;" />
      </div>
    </div>

    <!-- End .about_image -->

    <div class="description">
      <h1 class="name">{{ $t("ABOUT.name-description") }}</h1>
      <h2 class="subtitle" style="margin-bottom: 20px; font-size: 30px;">{{ $t("ABOUT.paragraph") }}</h2>
      <div class="description_inner">
        <div class="left">
          <p>
            {{ $t("ABOUT.description") }}
          </p>
          <p>
            {{ $t("ABOUT.second-description") }}
          </p>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <a><span class="birthday">  {{ $t("ABOUT.birthday-span") }}</span> {{ $t("ABOUT.birthday") }}</a>
            </li>
            <li>
              <a><span class="age">{{ $t("ABOUT.age-span") }}</span>{{ $t("ABOUT.age") }}</a>
            </li>
            <li>
              <p>
                <span class="email">{{ $t("ABOUT.email-span") }}</span><a href="mailto:phcunhawake@gmail.com">{{ $t("ABOUT.email") }}</a>
              </p>
            </li>
            <li>
              <p>
                <span>{{ $t("ABOUT.phone-span") }}</span><a href="tel:+5534991511851">{{ $t("ABOUT.phone") }}</a>
              </p>
            </li>
            <li>
              <p>
                <span>{{ $t("ABOUT.training-span") }}</span>
                <a>{{ $t("ABOUT.training") }}</a>
              </p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>
  </div>
</template>

<script>
// import Brand from "./Brand";
export default {
  components: {
    // Brand,
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
  },
  metaInfo() {
  return {
    title: 'Pedro Henrique Cunha - Atleta de Wakeboard e Inspiração',
    meta: [
      { property: 'og:title', content: 'Sobre' },
      { property: 'og:description', content: 'Conheça a emocionante trajetória de Pedro Henrique Cunha, um apaixonado atleta de wakeboard de Uberlândia. Superação, fé e determinação em sua jornada inspiradora.' },
      { property: 'og:keywords', content: 'Pedro Henrique Cunha, wakeboard, atleta, inspiração, Uberlândia, esporte, dedicação, paixão' },
    ],
  };
},


};
</script>

<style lang="scss">
.birthday {
  padding-right: 10px;
  letter-spacing: 0;
}

.age {
  min-width: 60px !important;
  letter-spacing: 0;
}

.email {
  min-width: 60px !important;
  letter-spacing: 0;
}

.tokyo_tm_about .description .right>ul>li a:hover {
  color: #000;
  letter-spacing: 1px;
  cursor: pointer;
}
</style>
