<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>{{ $t("PORTFOLIO.title") }}</span>
          <h3>{{ $t("PORTFOLIO.description") }} </h3>
        </div>
        <!-- End Left -->
        <div class="portfolio_filter">
          <ul>
            <li>
              <a v-on:click="activetab = 1" :class="[activetab === 1 ? 'active' : '']">{{ $t("PORTFOLIO.all") }}</a>
            </li>
            <!-- End All for tabcontent 1 -->
            <li>
              <a v-on:click="activetab = 2" :class="[activetab === 2 ? 'active' : '']">{{ $t("PORTFOLIO.instagram") }}</a>
            </li>
            <!-- End Instagram tabcontent 2 -->
            <li>
              <a v-on:click="activetab = 3" :class="[activetab === 3 ? 'active' : '']">{{ $t("PORTFOLIO.youtube") }}</a>
            </li>
            <!-- End .Youtube tabcontent 3 -->

          </ul>
        </div>
        <!-- End .portfolio-filter -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->

    <div v-if="activetab === 1" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="allItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li v-for="(image, imageIndex) in allItems" :key="imageIndex" @click="index = imageIndex">
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />

              <div class="play-overlay">
                <img class="img-play" src="../assets/img/portfolio/play.png" alt="imagem de play">
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="portfolio_list">

        <li v-for="(image, imageIndex) in instagramItems" :key="imageIndex">
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <a :href="getInstagramLink(image.src)" target="_blank" rel="noopener noreferrer">
                <img class="image" :src="image.thumb" alt="Portfolio" />
                <div class="play-overlay">
                  <img class="img-play" src="../assets/img/portfolio/play.png" alt="imagem de play">
                </div>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="instagramItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li v-for="(image, imageIndex) in instagramItems" :key="imageIndex">

          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <a :href="getInstagramLink(image.src)" target="_blank" rel="noopener noreferrer">
                <img class="image" :src="image.thumb" alt="Portfolio" />
                <div class="play-overlay">
                  <img class="img-play" src="../assets/img/portfolio/play.png" alt="imagem de play">
                </div>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="youtubeItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li v-for="(image, imageIndex) in youtubeItems" :key="imageIndex" @click="index = imageIndex">
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
              <div class="play-overlay">
                <img class="img-play" src="../assets/img/portfolio/play.png" alt="imagem de play">
              </div>
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 3 -->


  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      allItems: [
        {
          src: "https://www.youtube.com/watch?v=RxBZsdn7-iA",
          thumb: require("../assets/img/portfolio/ph-home-old.jpg"),
        },
        {
          src: "https://www.youtube.com/watch?v=wvcHGt5SeU0",
          thumb: require("../assets/img/portfolio/ph2.png"),
        },
        {
          src: "https://www.youtube.com/watch?v=8rPcRkh__Ao",
          thumb: require("../assets/img/portfolio/ph9.jpg"),
        },
        {
          src: "https://www.youtube.com/watch?v=mF_kU9I4LGs&t=2s",
          thumb: require("../assets/img/portfolio/ph1.png"),

        },

      ],
      instagramItems: [
        {
          src: "https://www.instagram.com/p/C1H-fecL22b/",
          thumb: require("../assets/img/portfolio/ph3.jpg"),
        },
        {
          src: "https://www.instagram.com/reel/C1e_t48rzga/",
          thumb: require("../assets/img/portfolio/ph4.png"),
        },
        {
          src: "https://www.instagram.com/reel/CviFG9Ep3Hz/?igshid=MTc4MmM1YmI2Ng%3D%3D",
          thumb: require("../assets/img/portfolio/ph6.png"),
        },
        {
          src: "https://www.instagram.com/reel/CsR_I9KJfR8/?igshid=MTc4MmM1YmI2Ng%3D%3D",
          thumb: require("../assets/img/portfolio/ph7.jpg"),
        },
        {
          src: "https://www.instagram.com/reel/CvnskHAsgDY/?igshid=MTc4MmM1YmI2Ng%3D%3D",
          thumb: require("../assets/img/portfolio/ph5.jpg"),
        },
        {
          src: " https://www.instagram.com/reel/CmejTSZLI_c/?igshid=MTc4MmM1YmI2Ng%3D%3D",
          thumb: require("../assets/img/portfolio/ph8.jpg"),
        },
        // Adicione mais itens conforme necessário
      ],
      youtubeItems: [
        {
          src: "https://www.youtube.com/watch?v=RxBZsdn7-iA",
          thumb: require("../assets/img/portfolio/ph-home-old.jpg"),
        },
        {
          src: "https://www.youtube.com/watch?v=wvcHGt5SeU0",
          thumb: require("../assets/img/portfolio/ph2.png"),
        },
        {
          src: "https://www.youtube.com/watch?v=8rPcRkh__Ao",
          thumb: require("../assets/img/portfolio/ph9.jpg"),
        },
        {
          src: "https://www.youtube.com/watch?v=mF_kU9I4LGs&t=2s",
          thumb: require("../assets/img/portfolio/ph1.png"),

        },
      ],


      index: null,
    };
  },
  methods: {
    getInstagramLink(instagramUrl) {
      return instagramUrl;
    },
  },
  metaInfo() {
    return {
      title: 'Portfólio - Registro de Momentos-Chave',
      meta: [
        { property: 'og:title', content: 'Portfólio - Registro de Momentos-Chave' },
        { property: 'og:description', content: 'Explore o portfólio de Pedro Henrique Cunha, incluindo momentos-chave em wakeboard.' },
      ],
    };
  },

};
</script>

<style lang="scss" scoped>
.img-play {
  height: 80px !important;
  width: 80px !important;
}

.play-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* Certifique-se de que a imagem do play esteja acima da imagem do portfólio */
}</style>
