<template>
    <div class="tokyo_tm_partners container">
        <div class="description">
            <div class="left">
                <div class="tokyo_tm_title">
                    <span>{{ $t("PARTNERS.title") }}</span>
                </div>
                <h3 class="title">{{ $t("PARTNERS.paragraph") }}</h3>
                <p>
                    {{ $t("PARTNERS.description") }}
                </p>
            </div>
            <div class="partners-container">
                <div class="first-section">
                    <img class="praia-club" src="../assets/img/partners/praia-club.png" alt="Logo praia club">
                </div>

                <div class="second-section">
                    <img class="cafe" src="../assets/img/partners/cafe-cajuba.png" alt="Logo Café cajuba">
                    <img class=" mr-ocky" src="../assets/img/partners/mr-ocky.png" alt="Logo Mr-ocky">
                    <img class=" bem-brasil" src="../assets/img/partners/bem-brasil.png" alt="Logo Bem brasil">
                </div>
                <div class="third-section">
                    <img class=" maqnelson" src="../assets/img/partners/maqnelson.png" alt="Logo Maqnelson">
                    <img class=" start" src="../assets/img/partners/start.png" alt="Logo Start">
                    <img class=" decio" src="../assets/img/partners/grupo-decio.png" alt="Logo Grupo Decio">
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Partners",
    data() {
        return {
            src1: require("../assets/img/slider/ph-home.jpg"),
        };
    },
    metaInfo() {
        return {

            title: 'Nossos Parceiros - Apoiando Pedro Henrique Cunha no Wakeboard',
            meta: [
                { property: 'og:title', content: 'Nossos Parceiros - Apoiando Pedro Henrique Cunha no Caminho para o Topo' },
                { property: 'og:description', content: 'Descubra os parceiros que acreditam no potencial e visão de Pedro Henrique Cunha no esporte do wakeboard.' },
            ],
        };
    },
};
</script>
  
<style lang="scss" >
.tokyo_tm_partners {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    padding: 100px 0px 100px 0px;
}

.tokyo_tm_partners .description {
    width: 100%;
    height: auto;
    clear: both;
}

.tokyo_tm_partners .description_inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tokyo_tm_partners .description .title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 26px;
}

.partners-container {

    justify-content: center;
}

.praia-club {
    width: 150px;
    height: 150px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.cafe {
    width: 100px;
    height: 100px;
}

.mr-ocky {
    width: 130px;
    height: 90px;
}

.bem-brasil {
    width: 150px;
    height: 80px;
}

.maqnelson {
    width: 150px;
    height: 80px;
}

.start {
    width: 100px;
    height: 80px;
}

.decio {
    width: 100px;
    height: 80px;
}

.first-section,
.second-section,
.third-section {
    display: flex;
    justify-content: center;
    padding: 0 20px !important;
}

.cafe,
.mr-ocky,
.bem-brasil {
    margin-right: 20px;
}

.maqnelson,
.start,
.decio {
    margin-right: 20px;
    margin-top: 20px;

}


.praia-club {
    width: 120px;
    height: 120px;
}

.bem-brasil {
    width: 101px;
    height: 49px;
    margin-top: 10px;
}

.cafe {
    width: 60px;
    height: 60px;
    margin: 0 10px 0 0;
}


.mr-ocky,
.maqnelson,
.start,
.decio {
    width: 80px;
    height: 80px;
    margin: 0 10px 0 0;



}
</style>
  