<template>
  <div>
    <!-- Start leftpart -->
    <div class="leftpart">
      <div class="leftpart_inner">
       
          <router-link to="/">
            <h1 class="logo-text">{{ $t("HOME-MENU.title") }}</h1>
        </router-link>
        <!-- End .logo -->
        <div class="menu">
          <a href="#"
            v-on:click="activetab = 1"
            :class="[activetab === 1 ? 'active' : '']"
          >
          <img loading="lazy" class="svg" src="../assets/img/svg/home-run.svg" alt="homerun" />

            <span class="menu_content">{{ $t("HOME-MENU.home") }}</span></a
          >
          <!-- End Home -->
          <a href="#"
            v-on:click="activetab = 2"
            :class="[activetab === 2 ? 'active' : '']"
          >
            <img class="svg" src="../assets/img/svg/avatar.svg" alt="avatar" />
            <span class="menu_content">{{ $t("HOME-MENU.about") }}</span></a
          >
          <!-- End About -->
          <a href="#"
            v-on:click="activetab = 3"
            :class="[activetab === 3 ? 'active' : '']"
          >
            <img class="svg" src="../assets/img/svg/partners.png" alt="partners" />
            <span class="menu_content">{{ $t("HOME-MENU.partners") }}</span></a
          >
          <!-- End Our Partners -->
          <a href="#"
            v-on:click="activetab = 4"
            :class="[activetab === 4 ? 'active' : '']"
          >
            <img
              class="svg"
              src="../assets/img/svg/briefcase.svg"
              alt="briefcase"
            />
            <span class="menu_content">{{ $t("HOME-MENU.portfolio") }}</span></a
          >
          <!-- End Portfolio -->
          <a href="#"
            v-on:click="activetab = 5"
            :class="[activetab === 5 ? 'active' : '']"
          >
            <img
              class="svg"
              src="../assets/img/svg/skills.png"
              alt="briefcase"
            />
            <span class="menu_content">{{ $t("HOME-MENU.skills") }}</span></a
          >
          <!-- End Portfolio -->
        
          <a href="#"
            v-on:click="activetab = 6"
            :class="[activetab === 6 ? 'active' : '']"
          >
            <img class="svg" src="../assets/img/svg/mail.svg" alt="mail" />
            <span class="menu_content"> {{ $t("HOME-MENU.contact") }}</span></a
          >
        <div class="icon-language" v-if="openedArticle">
          <input type="radio" id="en" v-model="$i18n.locale" value="en" style="display: none">
          <label for="en">

            <button @click="switchLanguage('en')" class="language">
              <img class="icon-en" src="../assets/img/icon-language/en-icon.svg" alt="English">
            </button>
          </label>

          <input type="radio" id="pt-BR" v-model="$i18n.locale" value="pt-BR" style="display: none">
          <label for="pt-BR">
            <button @click="switchLanguage('pt-BR')" class="language">
              <img class="icon-pt" src="../assets/img/icon-language/br-icon.svg" alt="Português">
            </button>
          </label>
        </div>

          <!-- End Contact -->
        </div>
        <div class="copyright">
          <p>
            &copy; {{ new Date().getFullYear() }} Pedro Cunha Wake<br />Created by
            <a
              href="https://www.dexidigital.com.br"
              target="_blank"
              rel="noreferrer"
            >
              Dexi Digital
            </a>
          </p>
        </div>
        <!-- End copyright -->
      </div>
      
    </div>
    
    <!-- End leftpart -->

    <!-- Start rightpart -->
    <div class="rightpart">
      <div class="rightpart_in">
        <div class="tokyo_tm_section">
          <div class="container">
            <div v-if="activetab === 1" class="tabcontent">
              <Home @setActiveTab="activetab = $event" />
            </div>
            <!-- End Home tabcontent -->
            <div v-if="activetab === 2" class="tabcontent">
              <About />
            </div>
            <!-- End About tabcontent  -->

            <div v-if="activetab === 3" class="tabcontent">
              <Partners />
            </div>
                <!-- End Partners tabcontent  -->
            <div v-if="activetab === 4" class="tabcontent">
              <Portfolio />
            </div>
            <!-- End Portfolio tabcontent -->
            <div v-if="activetab === 5" class="tabcontent">
              <Skills />
            </div>
            <div v-if="activetab === 6" class="tabcontent">
              <Contact />
            </div>
            <!-- End Contact tabcontent -->
          </div>
        </div>
      </div>
    </div>
    <!-- End rightpart -->
  </div>
</template>

<script>
  import Home from "../components/Home";
  import About from "../components/About";
  import Partners from "../components/Partners";
  import Portfolio from "../components/Portfolio";
  import Skills from "../components/Skills";
  import Contact from "../components/Contact";
  export default {
    components: {
      Home,
      About,
      Partners,
      Portfolio,
      Skills,
      Contact,
    },
    
    data() {
      return {
        activetab: 1,
      };
      
    },
    props: {
    openedArticle: {
      type: Boolean,
      default: true
    }
  },
  methods: {
 
    switchLanguage(language) {
      this.$i18n.locale = language;
      this.$store.commit('setLanguage', language);
      setTimeout(() => {
        location.reload();
      }, 300);
    }
  },
  };
</script>

<style lang="scss" scoped>
.logo-text{
 font-size: 35px;
 font-weight: bold;
}
.router-link-exact-active {
text-decoration: none;

}

.language{
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
    bottom: 11px;
}
@media screen and (min-width: 1200px) {
  .language{
    padding: 0 10px 0 0;

  }
}
@media screen and (max-width: 768px) {
  .language{
    position: relative;
    bottom: 3px;
    display: block;
  }
}
.icon-en{
  width: 20px;
  height: 16px;
}
.icon-pt{
  width: 20px;
  height: 16px;
  border: none;
}
.icon-language {
  display: flex;
  align-items: center;
  padding: 0 0 0 1px;
}



.v-list-item {
  display: block !important;
}
</style>
